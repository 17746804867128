<template>
  <div class="posts">
    <div class="content-menu">
      <div class="select-menu" @click="handlePostsMenu">
        <div
          class="menu-item"
          v-for="item in selectMenuConfig"
          :key="item.index"
          :data-type="item.type"
          :class="{ 'is-active': postsIndex == item.index }"
        >
          {{ item.name }}
        </div>
        <span>投稿分析不包含当日发布作品</span>
      </div>

      <div class="filter-box">
        <div class="account-filter filter-item">
          <span>账号筛选</span>
          <el-select
            v-model="filterParams.filterAccounts"
            multiple
            collapse-tags
            :popper-append-to-body="false"
            style="margin-left: 20px"
            placeholder="请选择"
            size="mini"
          >
            <el-option v-for="item in accountList" :key="item.id" :label="item.nickname" :value="item.id">
              <div class="account-item">
                <img v-if="!item.avatar" src="@/assets/img/image/blank_headPic.png" alt="" />
                <img v-else :src="item.avatar" alt="" />
                <span>{{ item.nickname }}</span>
              </div>
            </el-option>
          </el-select>
        </div>

        <div class="data-filter filter-item">
          <span>发布时间</span>
          <el-date-picker
            v-model="filterParams.filterTime"
            :clearable="false"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            popper-class="date-no-light"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <component
      :is="getComponents"
      :filterAccounts="filterParams.filterAccounts"
      :filterTime="filterParams.filterTime"
      :allAccounts="accountList.map((item) => item.id)"
    ></component>
  </div>
</template>

<script>
import PostsAnalysis from "@/components/dataCenter/PostsAnalysis.vue";
import PostsList from "@/components/dataCenter/PostsList.vue";
export default {
  name: "Posts",
  data() {
    return {
      postsIndex: "1",
      filterParams: {
        filterAccounts: [],
        filterTime: [],
      },
      accountList: [],
      selectMenuConfig: [
        {
          index: "1",
          name: "投稿分析",
          type: "analysis",
          component: "PostsAnalysis",
        },
        {
          index: "2",
          name: "投稿列表",
          type: "list",
          component: "PostsList",
        },
      ],
      // 日期不能选择今天以及今天以后的
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return time.getTime() > date.getTime();
          // return time.getTime() > Date.now()
        },
      },
    };
  },

  computed: {
    getComponents() {
      const component = this.selectMenuConfig.find((item) => item.index == this.postsIndex);
      return component.component;
    },
  },

  components: {
    PostsAnalysis,
    PostsList,
  },

  mounted() {
    this.getAccountList();
    this.initDefaultTime();
  },

  methods: {
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    initDefaultTime() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const twoMonthAgo = new Date(yesterday);
      // oneMonthAgo.setDate(yesterday.getDate() - 7);
      twoMonthAgo.setMonth(yesterday.getMonth() - 2);
      this.filterParams.filterTime = [this.formatDate(twoMonthAgo), this.formatDate(yesterday)];
    },

    handleSelect(key, keyPath) {
      // key - index
    },

    handlePostsMenu(event) {
      const type = event.target.dataset.type;
      if (!type) return;

      if (type === "analysis") {
        this.postsIndex = "1";
      } else if (type === "list") {
        this.postsIndex = "2";
      }
    },

    getAccountList() {
      let params = {
        page: 1,
        pageSize: 10,
        paging: 0,
      };
      this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
        if (res.code == 200) {
          this.accountList = res.data.data;
          this.filterParams.filterAccounts = this.accountList.map((item) => item.id);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.account-item {
  display: inline-block;
  // align-items: center;
  // gap: 3px;
  margin-left: 20px;
  img {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // background-color: black;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 3px;
    // background-color: black;
  }
}

::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected .account-item span {
  color: #409eff;
}

::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  left: 20px;
  top: 0;
}

.posts {
  // height: calc(100vh - 51px - 148px);
  padding: 24px 0 0 0;
  box-sizing: border-box;

  .content-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-menu {
      display: flex;
      align-items: center;
      gap: 8px;

      .menu-item {
        box-sizing: border-box;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: #f2f2f4;
        color: rgba(37, 38, 50, 0.8);
        font-family: PingFang SC, PingFang SC;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
        padding-inline: 18px;
        cursor: pointer;

        &.is-active {
          background-color: #ecebff;
          color: #594fee;
        }
      }

      span {
        margin-left: 8px;
        font-family: PingFang SC, PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: rgba(37, 38, 50, 0.6);
      }
    }

    .filter-box {
      display: flex;
      align-items: center;
      gap: 12px;

      .filter-item {
        background-color: #f2f2f4;
        border-radius: 4px;
        padding: 2px 12px;

        span {
          color: rgba(37, 38, 50, 0.6);
        }

        ::v-deep .el-input__inner {
          padding-left: 10px;
          background: transparent;
          border: none;
        }

        ::v-deep .el-date-editor .el-range-input {
          background-color: transparent;
        }

        ::v-deep .el-date-editor--daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
          width: 250px;
        }
      }
    }
  }

  ::v-deep .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #252632;
      display: flex;
      align-items: center;
      gap: 5px;

      .el-icon-question {
        margin-left: 3px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        font-style: normal;
        color: #bbbbbd;
        cursor: pointer;
      }
    }
  }
}
</style>